.Grade {
    background-color: var(--grey);
    color: white;
    padding: 0.2em 1em;
    border-radius: 1em;
    width: fit-content;
    min-width: 1em;
    text-align: center;
}

.bad {
    background-color: var(--red);
}

.enough {
    background-color: var(--orange);
}

.good {
    background-color: var(--green);
}