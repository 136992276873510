:root {
  --selection-color: #00A9F1;
  --placeholder-color: #9F9F9F;
  --green: #0EC520;
  --orange: #ECA10F;
  --red: #DC2727;
  --grey: #BCBCBC;
  --dark-grey: #444343;
  font-family: system-ui;
  font-size: 15pt;

}

body {
  --primary-color: black;
  --secondary-color: var(--dark-grey);
  --bg-color: white;
}

body {
  background-color: var(--bg-color);
  color: var(--primary-color);
  margin: 0px;
  height: 100vh;
}

body.dark {
  --primary-color: white;
  --secondary-color: var(--grey);
  --bg-color: var(--dark-grey)
}

a {
  text-decoration: none;
  color: var(--secondary-color);
}

a:hover {
  color: var(--selection-color);
}

h1 {
  font-size: 0.9em;
  font-weight: normal;
  color: var(--secondary-color);
}

select {
  background-color: transparent;
  color: var(--primary-color);
  border: var(--secondary-color) solid 2px;
  border-radius: 0.5em;
  height: 2em;
  font-size: inherit;
  padding: 0 0.5em;
}

select option {
  background-color: var(--bg-color);
}


.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.item-end {
  justify-self: end;
}

.item-center {
  justify-self: center;
}

.item-start {
  justify-self: start;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
}