.AppIcon {
    display: flex;
    position: relative;
    background-color: white;
    border-radius: 25px;
    width: 100px;
    height: 100px;
    box-shadow: 5px 5px 10px 1px #00000045;
    
}

.AppIcon:hover {
    cursor: pointer;
}

.AppIcon img {
    margin: auto;
}

.AppIcon span {
    position: absolute;
    right: -0.5em;
    background-color: var(--red);
    color: white;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    text-align: center;
}