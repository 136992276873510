.School {
    display: flex;
    font-size: inherit;
    align-items: center;
    justify-content: space-around;
    background-color: #383838;
    gap: 20px;
    width: 200px;
    height: 260px;
    border-radius: 20px;
    color: white;
    border: none;
    padding: 0;
}

.School:hover {
    cursor:pointer;
}

.School * {
    text-align: center;
}

.School h2 {
    font-size: 15pt;

}

