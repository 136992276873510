.ToggleButton {
    background-color: var(--secondary-color);
    border: var(--secondary-color) solid 2px;
}

.ToggleButton:hover {
    cursor: pointer;
}

.ToggleButton div {
    background-color: var(--selection-color);
    width: 50%;
    height: 100%;
    border-radius: 100%;
}

.ToggleButton .right {
    float: right;
}